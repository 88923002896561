import React, { Component } from 'react';
import { Link,Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class MakePayment extends Component {

    render() {

        var userToken = localStorage.getItem('token');
        if(! userToken) {
              return <Navigate  to='/login' replace="true" />
          }
         
          var request_data =   JSON.stringify({
            "setting": {
                "source": "EPG1"
            },
            "user_details": {
                "first_name": "Mark",
                "middle_name": "Geek",
                "last_name": "Zuk",
                "email": "mark_geek@gmail.com",
                "address": {
                    "street_first": "#20",
                    "city": "Melb",
                    "state": "victoria",
                    "country": "Australia",
                    "country_code": "US",
                    "pincode": "3000"
                }
            },
            "card_details": {
                "card_number": "4111111111111111",
                "exp_month": "12",
                "exp_year": "2023",
                "cvv": "123",
                "brand": "VISA"
            },
            "amount": 1000,
            "currency": "USD",
            "orderreference": "My_Order_123",
            "shopper_url": "http://localhost/dev/big_direct/redirect.php",
            "descriptor": "+918954133055"
        }, null, 2);

        var response_data = JSON.stringify({
            "status": "success",
            "data": {
                "status": true,
                "code": 200,
                "amount": "1000",
                "currency": "USD",
                "masked_card": "411111######1111",
                "orderreference": "My_Order_123",
                "transaction_id": "1673853034513",
                "transaction_timestamp": "2023-01-16 07:10:34"
            },
            "message": "payment success"
        }, null, 2);
      
        var respone_handle1 = `
        <form name='' action='preconditions.url' method='POST'>
            <INPUT type='hidden' name='preconditions.parameters[].name' value='preconditions.parameters[].value'>
        </form>
        <script>
            window.onload = submitForm;
            function submitForm() { downloadForm.submit(); }
        </script>`;

        var respone_handle2 = `
        <form name='' action='redirect.URL' method='POST'>
            <INPUT type='hidden' name='redirect.parameters[].name' value='redirect.parameters[].value'>
        </form>
        <script>
            window.onload = submitForm;
            function submitForm() { downloadForm.submit(); }
        </script>`;
        var intermediate_respone =  JSON.stringify({
            "status": "success",
            "data": {
              "status": true,
              "code": "000.200.000",
              "message": "transaction pending",
              "transaction_id": "8acda4a1877ad84701877e9b67bf5cb7",
              "transaction_timestamp": "2023-04-14 07:12:06+0000",
              "redirect": {
                "url": "https://secureicici-cr1.icicibank.com/v1/acs/services/browser/creq/L/8112/aa5f6719-da93-11ed-87bf-7fb25887cdc6",
                "parameters": [
                  {
                    "name": "creq",
                    "value": "eyJtZXNzYWdlVHlwZSI6IkNSZXEiLCJtZXNzYWdlVmVyc2lvbiI6IjIuMi4wIiwidGhyZWVEU1NlcnZlclRyYW5zSUQiOiI2NmY5MTA3ZC0zNDZmLTRmZDEtOTBiMy04NTM0ODcyZThmOTAiLCJhY3NUcmFuc0lEIjoiYWE1ZjY3MTktZGE5My0xMWVkLTg3YmYtN2ZiMjU4ODdjZGM2IiwiY2hhbGxlbmdlV2luZG93U2l6ZSI6IjA0In0"
                  },
                  {
                    "name": "threeDSSessionData",
                    "value": ""
                  }
                ],
                "preconditions": [
                  {
                    "origin": "iframe#hidden",
                    "waitUntil": "iframe#onload",
                    "description": "Hidden iframe post for 3D Secure 2.0",
                    "method": "POST",
                    "url": "methodURL",
                    "parameters": [
                      {
                        "name": "threeDSMethodData",
                        "value": "methodData"
                      }
                    ]
                  }
                ]
              }
            },
            "message": "transaction pending"
        }, null, 2);

        const copyClipboard = (e,data) => {
            console.log('copyClipboard');
            e.preventDefault();
            navigator.clipboard.writeText(data);
            toast.success('Data copied !', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }; 

        return (
            <div>
                <div class="docs-content">
                    <div class="container">
                        <article class="docs-article" id="section-1">
                            <h1 class="docs-heading">Make Payment</h1>
                            <section class="docs-intro">
                            <p>
                                    The E-Pocket API is organized around REST. This API accepts request bodies,
                                    returns JSON-encoded responses, and uses standard HTTP response codes.
                                </p>
                            </section>
                        </article>

                        <article class="docs-article" id="section-2">
                            <header class="docs-header">
                                <h1 class="docs-heading">Make Payment Reference</h1>
                                <section class="docs-intro row">
                                    <div class="col-lg-6 col-12">
                                    <p>
                                    This POST type 'make-payment' API uses payment source type and user's information like name, address and card details along with the amount and type of currency to create a transaction. This API is authorized and can be accessed using the token generated by 'generate-token' API only. 
                                        </p>

                                        <section class="docs-section row" id="item-2-3">
                                            <h2 class="section-heading">Api Params</h2>
                                            <div class="col-lg-6 col-12">
                                                <div class="attributes">


                                                    <div class="attributes-head">
                                                        <h4>Request Attributes</h4>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">setting</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Object</span><br></br>
                                                            Keys : <br>
                                                            </br>
                                                             source: <span class="highlight"> String</span>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">user_details</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Object</span><br></br>
                                                            Keys : <br>
                                                            </br>
                                                            first_name: <span class="highlight"> String</span>, &nbsp;
                                                            middle_name: <span class="highlight"> String</span>, &nbsp;
                                                            last_name: <span class="highlight"> String</span>, &nbsp;
                                                            email: <span class="highlight"> String</span>,  <br></br> 
                                                            address: <span class="highlight"> Object</span> :
                                                            &nbsp; Keys : <br>
                                                            </br> 
                                                            &nbsp; street_first: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp; city: <span class="highlight"> String</span>,&nbsp;
                                                             state: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp; country: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp; country_code: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp;pincode: <span class="highlight"> String</span>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">card_details</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Object</span><br></br>
                                                            Keys : <br>
                                                            </br>
                                                            card_number: <span class="highlight"> String</span>, &nbsp;
                                                            exp_month:  <span class="highlight"> String</span>, &nbsp;
                                                            exp_year:  <span class="highlight"> String</span>, 
                                                            cvv: <span class="highlight"> String</span>,
                                                            brand: <span class="highlight"> String</span>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">amount</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Number</span><br></br>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">currency</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">orderreference</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>
                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">shopper_url</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                            *Required only for EPG3 source
                                                        </p>
                                                    </div>
                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">descriptor</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                            *Optional
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12"></div>
                                        </section>

                                        <section class="docs-section row" id="item-2-4">
                                            <h2 class="section-heading">How to handle the responses for EPG3</h2>
                                            <div class="col-lg-12 col-12">
                                                <div class="attributes">
                                                    <div class="attributes-contents" style={{width:"100%"}}>
                                                        <p>
                                                            1.Open a hidden iframe and post data to the methodURL
                                                            <br></br>
                                                            Method Data and Method URL are not always returned. This is an optional step, but if it's returned it's important to handle it properly.
                                                            <pre style={{'margin-left': "-50px"}}>{respone_handle1}</pre> <br></br>
                                                            2.Redirect the shopper within and iframe to the redirect URL if onLoad event received from 1.
                                                            <pre style={{'margin-left': "-50px"}}>{respone_handle2}</pre><br></br>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12"></div>
                                            </section>
                                        
                                        <section class="docs-section row" id="item-2-4"> 
                                            <h2 class="section-heading">Allowed Brand for EPG3</h2>
                                            <div class="col-lg-6 col-12">
                                                <div class="attributes">

                                                <div class="attributes-head">
                                                    <h4>Attribute</h4>
                                                    <p>Param: <span class="highlight">brand</span> ,</p>
                                                    <p>Type: <span class="highlight">String</span> ,</p>
                                                    <p>Case-sensitive: <span class="highlight">True</span></p>
                                                </div>

                                                <div class="attributes-contents">

                                                    <p>
                                                    <span class="highlight">VISA</span>&nbsp;
                                                    <span class="highlight">MASTER</span>&nbsp; 
                                                    <span class="highlight">AFTERPAY</span>&nbsp; 
                                                    <span class="highlight">AIRPLUS</span>&nbsp; 
                                                    <span class="highlight">ALIA</span>&nbsp; 
                                                    <span class="highlight">ALIADEBIT</span>&nbsp; 
                                                    <span class="highlight">AMEX</span>&nbsp;<br></br>
                                                    <span class="highlight">APPLEPAY</span>&nbsp; 
                                                    <span class="highlight">ARGENCARD</span>&nbsp; 
                                                    <span class="highlight">AXP</span>&nbsp; 
                                                    <span class="highlight">BOLETO</span>&nbsp; 
                                                    <span class="highlight">CABAL</span>&nbsp; 
                                                    <span class="highlight">CABALDEBIT</span>&nbsp;<br></br> 
                                                    <span class="highlight">CARNET</span>&nbsp; 
                                                    <span class="highlight">CARTEBANCAIRE</span>&nbsp; 
                                                    <span class="highlight">CARTEBLEUE</span>&nbsp; 
                                                    <span class="highlight">CASHLINKMALTA</span>&nbsp; <br></br>
                                                    <span class="highlight">CENCOSUD</span>&nbsp;  
                                                    <span class="highlight">CLICK_TO_PAY</span>&nbsp; 
                                                    <span class="highlight">CLIQ</span>&nbsp;
                                                    <span class="highlight">DANKORT</span>&nbsp;
                                                    <span class="highlight">DINERS</span>&nbsp;<br></br>
                                                    <span class="highlight">DIRECTDEBIT_SEPA</span>&nbsp;
                                                    <span class="highlight">DISCOVER</span>&nbsp;
                                                    <span class="highlight">ELO</span>&nbsp;
                                                    <span class="highlight">GOOGLEPAY</span>&nbsp;
                                                    <span class="highlight">HEB_GIFT_CARD</span>&nbsp;<br></br>
                                                    <span class="highlight">HIPERCARD</span>&nbsp;
                                                    <span class="highlight">JCB</span>&nbsp;
                                                    <span class="highlight">MADA</span>&nbsp;
                                                    <span class="highlight">MAESTRO</span>&nbsp;
                                                    <span class="highlight">MASTERDEBIT</span>&nbsp;
                                                    <span class="highlight">MERCADOLIVRE</span>&nbsp;<br></br>
                                                    <span class="highlight">NARANJA</span>&nbsp;
                                                    <span class="highlight">NATIVA</span>&nbsp;
                                                    <span class="highlight">POSTPAY</span>&nbsp;
                                                    <span class="highlight">PREPAYMENT_VRP</span>&nbsp;
                                                    <span class="highlight">SCHEELS</span>&nbsp;
                                                    <span class="highlight">SEPA</span>&nbsp;<br></br>
                                                    <span class="highlight">SERVIRED</span>&nbsp;
                                                    <span class="highlight">SIBS_MULTIBANCO</span>&nbsp;
                                                    <span class="highlight">STAPLES</span>&nbsp;
                                                    <span class="highlight">TARJETASHOPPING</span>&nbsp;
                                                    <span class="highlight">TCARD</span>&nbsp;<br></br>
                                                    <span class="highlight">TCARDDEBIT</span>&nbsp;
                                                    <span class="highlight">UNIONPAY</span>&nbsp;
                                                    <span class="highlight">VALU</span>&nbsp;
                                                    <span class="highlight">VISADEBIT</span>&nbsp;
                                                    <span class="highlight">VISAELECTRON</span>&nbsp;
                                                    <span class="highlight">VPAY</span>
                                                    </p>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12"></div>
                                            </section>

                                    </div>
                                    <div class="col-lg-6 col-12 mr-4">
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">Api Endpoint</p>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        Method : POST <br />
                                                        &nbsp;Endpoint: /payments/make-payment
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">PRE-REQUEST API KEY</p>
                                                <svg style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, request_data);
                                                 }}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        {request_data}

                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox copybox-response mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">RESPONSE</p>
                                                <svg style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, response_data);
                                                 }}
                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code id="copy-txt" class="copybox-code ">
                                                        {response_data}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>

                                        <div class="copybox copybox-response mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">INTERMEDIATE RESPONSE FOR EPG3</p>
                                                <svg style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, intermediate_respone);
                                                 }}
                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code id="copy-txt" class="copybox-code ">
                                                        {intermediate_respone}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </section>


                            </header>

                        </article>


                        <article class="docs-article" id="section-3">
                            <section class="docs-section row" id="item-3-1">
                                <div class="col-12">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-between">
                                            <li class="page-item">
                                                <Link class="page-link" to="/generate-token" tabIndex="-1"
                                                    aria-disabled="true"><i class="bi bi-arrow-left"></i> &nbsp;
                                                    Generate Token
                                                </Link>
                                            </li>
                                            <li class="page-item">
                                                <Link class="page-link" to="/result-codes">
                                                    Result Codes for EPG3 &nbsp; <i class="bi bi-arrow-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                            </section>

                        </article>

                    </div>
                </div>
                <ToastContainer></ToastContainer>
            </div>
        )
    }

}