import React, {useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import useToken from '../../useToken';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// export default class About extends Component {
  const Login=()=>{ 

    const[email,setEmail]=useState(""); 
    const[passw,setPassw]=useState("");
    const { token, setToken } = useToken();


    async function loginUser() {

      const info={email:email,password:passw}; 

      return fetch('https://api.e-pocketgateway.com/client/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)

      }).then(data => data.json())

    }

    const handleSubmit = async e => {
      
      e.preventDefault();
      const response = await loginUser();
      if (response.status === "success") {
        toast.success('Login Successfully!!', {
          position: toast.POSITION.TOP_RIGHT
        });
        setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user_detail));
       return  window.location.href = "/generate-token";
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }

      var userToken = localStorage.getItem('token');
    if(userToken) {
      // return  window.location.href = "/generate-token";
          return <Navigate  to='/generate-token' />
      }

    return (
      <div>
        <div class="docs-content">
          <div class="container">
            <article class="docs-article" id="section-1">
              <h1 class="docs-heading">Login</h1>
              <section class="docs-intro">

                <form action="" onSubmit={handleSubmit}>
                  <div class="form-outline mb-4">
                    <label class="form-label" htmlFor="email">Email address</label>
                    <input type="email" name="email" id="email" class="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} style={{width: "40%"}}/> 
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" htmlFor="passw">Password</label>
                    <input type="password" name="passw" id="passw" class="form-control" value={passw} onChange={(e)=>setPassw(e.target.value)} style={{width: "40%"}}/>
                  </div>
                  <button type="submit" class="btn btn-primary btn-block mb-4">Login</button>

                </form>
              </section>
            </article>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    )

}

export default Login;