import React, { Component } from 'react';

export default class About extends Component {

  render() {
    return (
      <div>
        <div class="docs-content">
          <div class="container">
            <article class="docs-article" id="section-1">
              <h1 class="docs-heading">About Us</h1>
              <section class="docs-intro">
                <p>
                The e-pocket API is organised around REST. Our API has predictable resource-oriented URL’s, accepts form-encoded request bodies, returns JSON-encoded responses, and verbs.
              <br></br>
              <br></br>
              You can use the e-Pocket API in test mode, which doesn’t affect your live data or interact with the banking networks. The API key you use to authenticate the request determines whether the request is live mode or test mode..
              <br></br>
              <br></br>
              The e-Pocket API can  have some differences for every account as we release new versions and tailored functionalities. Log in to see docs customised to your version of the API, with your test key and data.
                </p>
              </section>
            </article>
          </div>
        </div>
      </div>
    )
  }

}