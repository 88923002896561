import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Commons/Header/Header'
import Sidebar from './components/Commons/Sidebar/Sidebar'
import Login from './components//Login/Login'
import Introduction from './components/api-pages/Introduction/Introduction'
import GenerateToken from './components/api-pages/GenerateToken/GenerateToken'
import MakePayment from './components/api-pages/MakePayment/MakePayment'
import ResultCodes from './components/api-pages/ResultCodes/ResultCodes'
import About from './components/About/About'
import Page404 from './components/Page404/Page404'
import useToken from './useToken';
import CurrenciesListing from './components/api-pages/CurrenciesListing/CurrenciesListing';
import GenerateQrString from './components/api-pages/GenerateQrString/GenerateQrString';
import TransExternalStatus from './components/api-pages/TransExternalStatus/TransExternalStatus';
import TransLookupStatus from './components/api-pages/TransLookupStatus/TransLookupStatus';
import TransactionStatus from './components/api-pages/TransactionStatus/TransactionStatus';

function App() {

  const { token, setToken } = useToken();

  return (
    <div className="App">
      <Router>
        
        <Header />
        <div className="docs-wrapper">
          <Sidebar />
        </div>
        <Routes>
          <Route path="/" exact element={<Introduction />}></Route>
          <Route path="login" exact element={<Login/>}></Route>
          <Route path="introduction" exact element={<Introduction />}></Route>
          <Route path="generate-token" exact element={ <GenerateToken />}></Route>
          <Route path="make-payment" exact element={ <MakePayment />}></Route>
          <Route path="result-codes" exact element={ <ResultCodes />}></Route>
          <Route path="transaction-details" exact element={ <TransactionStatus />}></Route>
          <Route path="currencies-listing" exact element={ <CurrenciesListing />}></Route>
          <Route path="generate-qr-string" exact element={ <GenerateQrString />}></Route>
          <Route path="on-chain-trans-status" exact element={ <TransExternalStatus />}></Route>
          <Route path="trans-lookup-status" exact element={ <TransLookupStatus />}></Route>
          <Route path="about" exact element={<About />}></Route>
          <Route path="*" exact element={<Page404 />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
