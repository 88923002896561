import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Header extends Component {

    render() {
        return (
            <div>
                <div id="docs-sidebar" class="docs-sidebar sidebar-visible">
                    <nav id="docs-nav" class="docs-nav navbar flex-gap-3">
                        <ul class="section-items list-unstyled nav flex-column pb-3">
                            <li class="nav-item">
                                <NavLink class="nav-link scrollto fs-7"
                                    to="/introduction">
                                    <p class="fw-bold">Introduction</p>
                                </NavLink>
                            </li>

                            <li class="nav-item">
                                <NavLink class="nav-link scrollto fs-7"
                                    to="/generate-token">
                                    <p class="fw-bold">Generate Token</p>
                                </NavLink>
                            </li>

                            <li class="nav-item">
                                <NavLink class="nav-link scrollto fs-7"
                                    to="/make-payment">
                                    <p class="fw-bold">Make Payment</p>
                                </NavLink>
                            </li>

                            <li class="nav-item">
                                <NavLink class="nav-link scrollto fs-7"
                                    to="/result-codes">
                                    <p class="fw-bold">Result Codes for EPG3</p>
                                </NavLink>
                            </li>

                            <li class="nav-item">
                                <NavLink class="nav-link scrollto fs-7"
                                    to="/transaction-details">
                                    <p class="fw-bold">Get Transaction Details</p>
                                </NavLink>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link scrollto fs-7" data-bs-toggle="collapse" href="#nav-intergrations-collapse" data-bs-target="#nav-intergrations-collapse" aria-expanded="false" aria-controls="nav-intergrations-collapse">
                                    <p class="fw-bold">Crypto Payments</p>
                                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5833 2.14581L8.58329 9.16665C8.49996 9.24998 8.40968 9.30887 8.31246 9.34331C8.21524 9.37831 8.11107 9.39581 7.99996 9.39581C7.88885 9.39581 7.78468 9.37831 7.68746 9.34331C7.59024 9.30887 7.49996 9.24998 7.41663 9.16665L0.395793 2.14581C0.201348 1.95137 0.104126 1.70831 0.104126 1.41665C0.104126 1.12498 0.208293 0.874981 0.416626 0.666648C0.624959 0.458314 0.868015 0.354148 1.14579 0.354148C1.42357 0.354148 1.66663 0.458314 1.87496 0.666648L7.99996 6.79165L14.125 0.666648C14.3194 0.472203 14.5588 0.374981 14.8433 0.374981C15.1283 0.374981 15.375 0.479147 15.5833 0.687481C15.7916 0.895814 15.8958 1.13887 15.8958 1.41665C15.8958 1.69443 15.7916 1.93748 15.5833 2.14581Z"
                                            fill="#828282" />
                                    </svg>
                                </a>
                            </li>
                            <div class="collapse" id="nav-intergrations-collapse">
                                <div class="">
                                    <ul>
                                        <li class="nav-item">
                                            <NavLink to='/currencies-listing' class="nav-link scrollto">Currencies Listing</NavLink>
                                        </li>
                                        <li class="nav-item">
                                        <NavLink to='/generate-qr-string' class="nav-link scrollto">Generate QR String</NavLink>
                                        </li>
                                        <li class="nav-item">
                                        <NavLink to='/on-chain-trans-status' class="nav-link scrollto">On-chain Transaction Status</NavLink>
                                        </li>
                                        <li class="nav-item">
                                        <NavLink to='/trans-lookup-status' class="nav-link scrollto">Transaction Lookup Status</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        </ul>
                    </nav>

                </div>


            </div>
        )
    }

}