import React, { Component } from 'react';

export default class Page404 extends Component {

  render() {
    return (
      <div>
        <h1>404 Page Not Found </h1>
      </div>
    )
  }

}