import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default class Introduction extends Component {

    render() {

        var baseStagingUrl = 'https://staging.e-pocketgateway.com/v1/api';
        var baseProdUrl = 'https://api.e-pocketgateway.com/v1/api';

        const copyClipboard = (e,data) => {
            e.preventDefault();
            navigator.clipboard.writeText(data);
            toast.success('Data copied!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        };

        return (
            <div>
                <div class="docs-content">
                    <div class="container">
                        <article class="docs-article" id="section-1">
                            <h1 class="docs-heading">Introduction Reference</h1>
                            <section class="docs-intro">
                                <p>
                                The e-pocket API is organised around REST. Our API has predictable resource-oriented URL’s, accepts form-encoded request bodies, returns JSON-encoded responses, and verbs.
                                </p>
                            </section>
                        </article>

                        <article class="docs-article" id="section-2">
                            <header class="docs-header">
                                <h1 class="docs-heading">Base Url Details</h1>
                                <section class="docs-intro row">
                                    <div class="col-lg-6 col-12">

                                        <p>
                                        You can use the e-Pocket API in test mode, which doesn’t affect your live data or interact with the banking networks. The API key you use to authenticate the request determines whether the request is live mode or test mode..
                                        <br></br>
                                        <br></br>
                                        The e-Pocket API can  have some differences for every account as we release new versions and tailored functionalities. Log in to see docs customised to your version of the API, with your test key and data.

                                        </p>

                                    </div>
                                    <div class="col-lg-6 col-12 mr-4">
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">Staging Base URL</p>
                                                <svg style={{cursor: "pointer"}}
                                                 onClick={(e) => {
                                                    copyClipboard(e, baseStagingUrl);
                                                 }}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                    {baseStagingUrl}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">Production Base URL</p>
                                                <svg style={{cursor: "pointer"}}
                                                 onClick={(e) => {
                                                    copyClipboard(e, baseProdUrl);
                                                 }}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                    {baseProdUrl}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </header>

                            <section class="docs-section row" id="item-3-1">
                                <div class="col-12">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-between">
                                            <li class="page-item"></li>
                                            <li class="page-item">
                                                <Link class="page-link" to="/generate-token">
                                                    Generate Token  &nbsp;
                                                    <i class="bi bi-arrow-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                            </section>

                        </article>

                    </div>
                </div>
                <ToastContainer closeOnClick autoClose={2000}></ToastContainer>
            </div>
        )
    }

}