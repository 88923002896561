import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class GenerateToken extends Component {

    

    render() {

        var userToken = localStorage.getItem('token');
        if(! userToken) {
              return <Navigate  to='/login' replace="true" />
          }

        var request_data =   JSON.stringify({
            "client_id": "7bcff8aca4ac8633817f073b7516025323b23ca0",
            "secret_key": "1eea929166c9ff3210abb9258c9a3c934f3b6267"
        }, null, 2);

        var response_data = JSON.stringify({
            "status": "success",
            "data": {
                "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqd3RfcmFuZG9tX21lcmNoYW50IjoiNGEwMWFmYjA4ZDRiYjMwNmVkMTEiLCJjbGllbnRfaWQiOiJVMkZzZEdWa1gxOHNSU0t0VzhZU2xqQjlpbGhqN1UyNUU2UUxkNVNQQ3N0dWJDTE9jOURYSFQzZFhRaXdRZUFtKzdxVURhbG9heDU1WlJKRnlsOHlNdz09Iiwic2VjcmV0X2tleSI6IlUyRnNkR1ZrWDErWGJVb2RPWkJsZDlXNW00TE4yV05FK3gvd1BrVXVkN3M5MlExT2d6V2JBV1VRNVhWcGYvL1NTTUVFemErOW9mVlh2ZEF5MVF1c01nPT0iLCJpYXQiOjE2NzM4MDE3MTksImV4cCI6MTY3MzgwNzcxOX0.H5LM7ZddesrpWCdSS5p2viUiMC4KrXOkzYoCJ1TW4Mc"
            },
            "message": "generate token success"
        }, null, 2);
      
        const copyClipboard = (e,data) => {
            console.log('copyClipboard');
            e.preventDefault();
            navigator.clipboard.writeText(data);
            toast.success('Data copied !', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        };

        return (
            <div>
                <div class="docs-content">
                    <div class="container">
                        <article class="docs-article" id="section-1">
                            <h1 class="docs-heading">Generate Token</h1>
                            <section class="docs-intro">
                                <p>
                                    The E-Pocket API is organized around REST. This API accepts request bodies,
                                    returns JSON-encoded responses, and uses standard HTTP response codes.
                                </p>
                            </section>
                        </article>

                        <article class="docs-article" id="section-2">
                            <header class="docs-header">
                                <h1 class="docs-heading">Generate Token Reference</h1>
                                <section class="docs-intro row">
                                    <div class="col-lg-6 col-12">
                                        <p>
                                            This POST type 'generate-token' API uses Merchant's secret key to generate a unique token.
                                            This token will further be used with other API's for authentication.
                                            The requested secret key contains important information, so be sure to hold them secure! 
                                        </p>
                                        <p>The generated token is used in the Headers of every other requests for authorization. i.e</p>
                                        <p>Authorization: Bearer 'generated-token'</p>

                                        <section class="docs-section row" id="item-2-3">
                                            <h2 class="section-heading">Api Params</h2>
                                            <div class="col-lg-6 col-12">
                                                <div class="attributes">


                                                    <div class="attributes-head">
                                                        <h4>Request Attributes</h4>
                                                    </div>


                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">client_id</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                            Length : <span class="highlight"> 40 characters</span>
                                                           
                                                        </p>
                                                    </div>



                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">secret_key</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                            Length : <span class="highlight"> 40 characters</span>
                                                           
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12"></div>
                                        </section>

                                    </div>
                                    <div class="col-lg-6 col-12 mr-4">
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">Api Endpoint</p>
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        Method : POST <br />
                                                        &nbsp;Endpoint: /generate-token
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">PRE-REQUEST API KEY</p>
                                                <svg style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, request_data);
                                                 }}
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8" />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        {request_data}

                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox copybox-response mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">RESPONSE</p>
                                                <svg 
                                                style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, response_data);
                                                 }} 
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code id="copy-txt" class="copybox-code ">
                                                        {response_data}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </section>


                            </header>

                        </article>


                        <article class="docs-article" id="section-3">
                            <section class="docs-section row" id="item-3-1">
                                <div class="col-12">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-between">
                                            <li class="page-item">
                                                <Link class="page-link" to="/introduction" tabIndex="-1"
                                                    aria-disabled="true"><i class="bi bi-arrow-left"></i> &nbsp;
                                                    Introduction
                                                </Link>
                                            </li>
                                            <li class="page-item">
                                                <Link class="page-link" to="/make-payment">
                                                    Make Payment Api &nbsp; <i class="bi bi-arrow-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                            </section>

                        </article>

                    </div>
                </div>
                <ToastContainer closeOnClick autoClose={2000}></ToastContainer>
            </div >
        )
    }

}