import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class GenerateQrString extends Component {

    

    render() {

        var userToken = localStorage.getItem('token');
        if(! userToken) {
              return <Navigate  to='/login' replace="true" />
          }

        var request_data =   JSON.stringify({
            "setting": {
              "source": "EPC1"
            },
            "user_details": {
              "first_name": "Mark",
              "middle_name": "Geek",
              "last_name": "Zuk",
              "email": "mark.epocket@yopmail.com",
              "address": {
                "street_first": "#20",
                "city": "Melb",
                "state": "victoria",
                "country": "Australia",
                "pincode": "3000"
              }
            },
            "amount": "2",
            "currency": "USD",
            "crypto_currency": "ETH",
            "orderreference": "My_Order_123"
          }, null, 2);

        var response_data = JSON.stringify({
            "status": "success",
            "data": {
              "id": "cedf1f7c-4834-4ad0-a327-a82a3145465b",
              "order_id": "1675528745482",
              "currency": "USD",
              "cryto_currency": "ETH",
              "total_amount": "0.0012119028",
              "qr_string": "ethereum:0xf574c88c6D59746F01d3feA2918e30690872B353?amount=0.00121190&value=0.00121190",
              "receiver_address": "0xf574c88c6D59746F01d3feA2918e30690872B353"
            },
            "message": "payment success, it will take time to update"
          }, null, 2);
      
        const copyClipboard = (e,data) => {
            console.log('copyClipboard');
            e.preventDefault();
            navigator.clipboard.writeText(data);
            toast.success('Data copied !', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        };

        return (
            <div>
                <div class="docs-content">
                    <div class="container">
                        <article class="docs-article" id="section-1">
                            <h1 class="docs-heading">Generate QR String</h1>
                            <section class="docs-intro">
                                <p>
                                    The E-Pocket API is organized around REST. This API accepts request bodies,
                                    returns JSON-encoded responses, and uses standard HTTP response codes.
                                </p>
                            </section>
                        </article>

                        <article class="docs-article" id="section-2">
                            <header class="docs-header">
                                <h1 class="docs-heading">Generate QR String Reference</h1>
                                <section class="docs-intro row">
                                    <div class="col-lg-6 col-12">
                                        <p>
                                            This POST type 'generate-qr-string' API uses payment source type and user's information 
                                            along with the amount and type of crypto currency to create a string which is used further for generating the QR code
                                            to generate crypto transactions. 
                                       </p>

                                        <section class="docs-section row" id="item-2-3">
                                            <h2 class="section-heading">Api Params</h2>
                                            <div class="col-lg-6 col-12">
                                                <div class="attributes">


                                                    <div class="attributes-head">
                                                        <h4>Request Attributes</h4>
                                                    </div>


                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">setting</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Object</span><br></br>
                                                            Keys : <br>
                                                            </br>
                                                             source: <span class="highlight"> String</span>
                                                        </p>
                                                    </div>
                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">user_details</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> Object</span><br></br>
                                                            Keys : <br>
                                                            </br>
                                                            first_name: <span class="highlight"> String</span>, &nbsp;
                                                            middle_name: <span class="highlight"> String</span>, &nbsp;
                                                            last_name: <span class="highlight"> String</span>,
                                                            email: <span class="highlight"> String</span>,  <br></br> 
                                                            address: <span class="highlight"> Object</span> :
                                                            &nbsp; Keys : <br></br>
                                                            &nbsp; street_first: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp; city: <span class="highlight"> String</span>,&nbsp;
                                                             state: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp; country: <span class="highlight"> String</span>,&nbsp;
                                                            &nbsp;pincode: <span class="highlight"> String</span>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">amount</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">currency</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">crypto_currency</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>

                                                    <div class="attributes-contents">
                                                        <div class="attributes-contents-head">
                                                            <span class="font-weight-bold">orderreference</span>&nbsp;
                                                        </div>
                                                        <p>
                                                            Type : <span class="highlight"> String</span><br></br>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12"></div>
                                        </section>

                                    </div>
                                    <div class="col-lg-6 col-12 mr-4">
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">Api Endpoint</p>
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        Method : POST <br />
                                                        &nbsp;Endpoint: /crypto/generate-qr-string
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">PRE-REQUEST API KEY</p>
                                                <svg style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, request_data);
                                                 }}
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8" />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code class="copybox-code">
                                                        {request_data}

                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div class="copybox copybox-response mb-3">
                                            <div class="copybox-top">
                                                <p class="copybox-para">RESPONSE</p>
                                                <svg 
                                                style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    copyClipboard(e, response_data);
                                                 }} 
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V7C3 6.71667 3.096 6.479 3.288 6.287C3.47933 6.09567 3.71667 6 4 6C4.28333 6 4.521 6.09567 4.713 6.287C4.90433 6.479 5 6.71667 5 7V20H15C15.2833 20 15.521 20.096 15.713 20.288C15.9043 20.4793 16 20.7167 16 21C16 21.2833 15.9043 21.5207 15.713 21.712C15.521 21.904 15.2833 22 15 22H5Z"
                                                        fill="#7281B8"
                                                    />
                                                </svg>
                                                {/* <span class="highlight copied-msg">text copied</span> */}
                                            </div>
                                            <div class="copybox-bottom">
                                                <pre>
                                                    <code id="copy-txt" class="copybox-code ">
                                                        {response_data}
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                            </header>

                        </article>


                        <article class="docs-article" id="section-3">
                            <section class="docs-section row" id="item-3-1">
                                <div class="col-12">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-between">
                                            <li class="page-item">
                                                <Link class="page-link" to="/currencies-listing" tabIndex="-1"
                                                    aria-disabled="true"><i class="bi bi-arrow-left"></i> &nbsp;
                                                    Currencies Listing API
                                                </Link>
                                            </li>
                                            <li class="page-item">
                                                <Link class="page-link" to="/on-chain-trans-status">
                                                On-chain Transaction Status  &nbsp; <i class="bi bi-arrow-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>

                            </section>

                        </article>

                    </div>
                </div>
                <ToastContainer closeOnClick autoClose={2000}></ToastContainer>
            </div >
        )
    }

}