import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default class Header extends Component {

    
    render() {
        var userToken = localStorage.getItem('token');

       function logout(){
            localStorage.clear();
            toast.success('Success', {
                position: toast.POSITION.TOP_RIGHT
            });
            window.location.href = "/introduction";
        }
       
        return (
            <div>
                <header class="header fixed-top">
                    <div class="branding docs-branding">
                        <div class="container-fluid position-relative py-2">
                            <div class="docs-logo-wrapper">
                                <button id="docs-sidebar-toggler" class="docs-sidebar-toggler docs-sidebar-visible me-2 d-xl-none" type="button">
                                    <span></span>
                                    <span></span>
                                    <span></span> 
                                </button>
                                <div class="site-logo">
                                    <Link class="navbar-brand" to="/" >
                                        <img
                                            class="logo-icon me-1"
                                            src="../../../assets/images/logoW.png"
                                            alt="logo"
                                        /><span class="logo-text">API</span>
                                    </Link>
                                </div>
                            </div>
                            {/* docs-logo-wrapper */}
                            <div class="docs-top-utilities d-flex justify-content-end align-items-center">
                                <ul class="list-inline mx-md-3 mx-lg-5 mb-0 d-none d-lg-flex">
                                    <li class="list-inline-item">
                                        <Link to='/about'>About</Link>
                                    </li>
                                   
                                    {/* <li class="list-inline-item">
                                        <Link to='/'>Docs</Link>
                                    </li> */}
                                </ul>
                                {/* social-list */}
                              {userToken ?  <button onClick={logout} class="btn btn-primary d-none d-lg-flex">Logout</button> :  <Link to="/login" class="btn btn-primary d-none d-lg-flex">Login</Link>} 
                            </div>
                        </div>
                    </div>
                </header>
                <ToastContainer></ToastContainer>
            </div>
        )
    }

}